* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.register-steps > div {
  border-bottom: 0.2rem solid #5c5c5c;
  color: #5c5c5c;
}

.register-steps > div.active {
  border-bottom: 0.2rem solid #5f34c2;
  color: #5f34c2;
}
.cardZiare:hover .imagineCardZiare {
  transform: scale(1.5);
}

.coverCardZiare {
  visibility: hidden;
}
.cardZiare:hover .coverCardZiare {
  visibility: visible;
}

.ziareRegCheck .chakra-checkbox__control {
  border-color: #d6bcfa;
  align-items: flex-start;
}
.ziareRegCheck .chakra-checkbox__control[data-disabled] {
  border-color: #9f7aea;
  background-color: #9f7aea;
  align-items: flex-start;
}
